import { Commit } from "vuex";
import { api } from "@/api/Api";
import { IBalancesHistory } from "@/store/interfaces/balances/IBalancesHistory";
import { IBaseQuery } from "@/api/interfaces/IApi";
import {
  balancesHistoryData,
  userBalanceData
} from "@/api/interfaces/balances/common";

export interface balanceState {
  balanceHistory: IBalancesHistory[];
  totalBalanceHistory: string;
  userBalance: string;
}
export const balancesModule = {
  state(): balanceState {
    return {
      balanceHistory: [],
      totalBalanceHistory: "",
      userBalance: ""
    };
  },

  getters: {
    balanceHistory: ({ balanceHistory }: balanceState): IBalancesHistory[] => {
      return balanceHistory;
    },
    totalBalanceHistory: ({
      totalBalanceHistory
    }: balanceState): string | number => {
      return totalBalanceHistory;
    },
    userBalance: ({ userBalance }: balanceState): string | number => {
      return userBalance;
    }
  },

  mutations: {
    setBalanceHistory(state: balanceState, balanceList: IBalancesHistory[]) {
      state.balanceHistory = balanceList;
    },
    setTotalBalanceHistory(state: balanceState, total: string) {
      state.totalBalanceHistory = total;
    },
    setUserBalance(state: balanceState, amount: string) {
      state.userBalance = amount;
    }
  },

  actions: {
    async fetchBalanceHistory(
      { commit }: { commit: Commit },
      {
        viewConfiguration: query,
        userId
      }: { viewConfiguration: IBaseQuery; userId: number }
    ): Promise<balancesHistoryData> {
      const response: balancesHistoryData = await api.fetchBalanceHistory(
        userId,
        query
      );

      const { data, success } = response;
      if (success) {
        commit("setBalanceHistory", data?.results);
      }
      if (success) {
        commit("setTotalBalanceHistory", data?.total);
      }

      return response;
    },

    async fetchUserBalance(
      {
        commit
      }: {
        commit: Commit;
      },
      userId: number
    ): Promise<userBalanceData> {
      const response: userBalanceData = await api.fetchUserBalance(userId);

      const { data, success } = response;

      if (success) {
        commit("setUserBalance", data?.amount || 0);
      }

      return response;
    },
  }
};
