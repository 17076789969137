import { apiRequestType, IApiResponse } from "@/api/interfaces/IApi";
import {
  fetchLocationPackagesDto,
  fetchLocationPackagesRatesDto,
  ILocationPackage,
  IRemoveLocationPackageDto,
  IUpdateLocationPackageDto
} from "@/api/services/packages/esim/location-packages/types";
import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";
import {
  IFetchLocationPackagesRatesResponse,
  IFetchLocationPackagesResponse
} from "@/api/services/packages/esim/location-packages/responseTypes";
import { TLocationRates } from "@/store/locationPackages";
export const locationPackageService = {
  async fetchLocationPackages(
    request: apiRequestType,
    options: fetchLocationPackagesDto
  ): Promise<IFetchLocationPackagesResponse> {
    const { locationType, params } = options;
    if (locationType === "countries")
      return {
        data: [] as ILocationPackage[]
      } as IFetchLocationPackagesResponse;
    const queryString = queryObjectToUrlString(params);

    const response = await request(
      `/packages-esim/${locationType}?${queryString}`,
      "get",
      {}
    );

    const data: ILocationPackage[] = <ILocationPackage[]>response.data;

    return { ...response, data };
  },

  async fetchLocationPackagesRates(
    request: apiRequestType,
    options: fetchLocationPackagesRatesDto
  ): Promise<IFetchLocationPackagesRatesResponse> {
    const { locationType } = options;

    const response = await request(
      `/packages-esim/rates/${locationType}`,
      "get",
      {}
    );

    const data: TLocationRates = <TLocationRates>response.data;

    return { ...response, data };
  },

  async updateLocationPackages(
    request: apiRequestType,
    data: IUpdateLocationPackageDto
  ): Promise<IApiResponse> {
    let locationType: string;
    const { country, region } = data;

    if (country) {
      locationType = "country";
    } else if (region) {
      locationType = "region";
    } else {
      locationType = "subregion";
    }

    return await request(
      `/packages-esim/${locationType}/package`,
      "post",
      data
    );
  },

  async removeLocationPackage(
    request: apiRequestType,
    { packageId, locationType }: IRemoveLocationPackageDto
  ): Promise<IApiResponse> {
    return await request(
      `/packages-esim/${locationType}/package/${packageId}`,
      "delete"
    );
  }
};
