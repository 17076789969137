export const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => {
      return import(/* webpackChunkName: "login" */ "../views/Login.vue");
    },
    showInSidebar: false,
    id: "login",
    parentId: null
  },

  {
    path: "/test",
    name: "test",
    component: () => {
      return import(/* webpackChunkName: "login" */ "../views/Test.vue");
    }
  },
  {
    path: "/",
    name: "Home",
    component: () => {
      return import(/* webpackChunkName: "Accounts" */ "../views/Accounts");
    },
    showInSidebar: false,
    id: "home",
    parentId: null
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "dashboard",
    showInSidebar: false,
    component: null,
    id: "dashboard",
    parentId: null
  },

  {
    path: "/accounts",
    name: "Accounts",
    icon: "accounts",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "Accounts" */ "../views/Accounts");
    },
    id: "accounts",
    parentId: null,
    count: 0
  },

  {
    path: "/user",
    name: "User",
    icon: null,
    id: "user",
    parentId: "accounts",
    component: () => {
      return import(/* webpackChunkName: "User" */ "../views/User.vue");
    }
  },
  // {
  //   path: "/hold-users-list",
  //   name: "Hold users list",
  //   icon: "hold-users",
  //   showInSidebar: true,
  //   component: () => {
  //     return import(
  //       /* webpackChunkName: "HoldUsersList" */ "../views/HoldUsersList.vue"
  //     );
  //   },
  //   id: "hold-users-list",
  //   parentId: null,
  //   count: 0
  // },

  {
    path: "/templates",
    name: "Templates",
    icon: "templates",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "Templates" */ "../views/Templates.vue"
      );
    },
    id: "templates",
    parentId: null
  },
  {
    path: "/packages",
    name: "Packages",
    icon: "packages",
    showInSidebar: true,
    component: null,
    id: "packages",
    parentId: null
  },
  {
    path: "/eSIM",
    name: "eSIM",
    icon: null,
    component: null,
    id: "eSIM",
    parentId: "packages",
    showInSidebar: true
  },

  {
    path: "/esim/packages/regions",
    name: "Region Packages",
    icon: null,
    showInSidebar: true,
    parentId: "eSIM",
    id: "/esim/packages/regions",

    component: () =>
      import(
        /* webpackChunkName: "subregionPackages.vue" */ "../views/packages/eSIM/LocationPackages.vue"
      )
  },
  {
    path: "/eSIM/carriers",
    name: "Carriers",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-Carriers" */ "../views/packages/eSIM/Carriers"
      );
    },
    id: "/eSIM/сarriers",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/plans",
    name: "Plans",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-plans" */ "../views/packages/eSIM/eSIMPlans"
      );
    },
    id: "/eSIM/plans",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/directory",
    name: "Directory",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-directory" */ "../views/packages/eSIM/EsimDirectory"
      );
    },
    id: "/eSIM/directory",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/profiles",
    name: "Profiles",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-profiles" */ "../views/packages/eSIM/ESIMProfiles"
      );
    },
    id: "/eSIM/profiles",
    parentId: "eSIM"
  },
  {
    path: "/eSIM/bundles",
    name: "Bundles",
    icon: null,
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "esim-profiles" */ "../views/packages/eSIM/ESIMBundles.vue"
      );
    },
    id: "/eSIM/bundles",
    parentId: "eSIM"
  },
  // {
  //   path: "/operators",
  //   name: "Operators",
  //   icon: "operators",
  //   component: () => {
  //     return import(/* webpackChunkName: "about" */ "../views/Operators.vue");
  //   },
  //   parentId: null,
  //   id: "operators",
  //   showInSidebar: true
  // },
  {
    path: "/operator",
    name: "Operator",
    icon: null,
    component: () => {
      return import(/* webpackChunkName: "operator" */ "../views/Operator.vue");
    },
    parentId: "operators",
    id: "operator",
    showInSidebar: false
  },
  {
    path: "/admins",
    name: "Admins",
    icon: "admins",
    component: () => {
      return import(/* webpackChunkName: "admins" */ "../views/Admins.vue");
    },
    parentId: null,
    id: "admins",
    showInSidebar: true
  },
  {
    path: "/admin",
    name: "Admin",
    icon: null,
    component: () => {
      return import(/* webpackChunkName: "admin" */ "../views/Admin.vue");
    },
    parentId: "admins",
    id: "admin",
    showInSidebar: false
  },

  {
    path: "/payments",
    name: "Payments",
    icon: "payments",
    component: () => {
      return import(/* webpackChunkName: "payments" */ "../views/Payments.vue");
    },
    parentId: null,
    id: "payments",
    showInSidebar: true
  },
  // {
  //   path: "/notification-center",
  //   name: "Notification center",
  //   icon: "notification-center",
  //   component: null,
  //   parentId: null,
  //   id: "notification-center",
  //   showInSidebar: true
  // },
  {
    path: "/locations",
    name: "Locations",
    icon: "locations",
    component: () => {
      return import(
        /* webpackChunkName: "locations" */ "../views/Locations.vue"
      );
    },
    parentId: null,
    id: "locations",
    showInSidebar: true
  },
  {
    path: "/pay-as-you-go",
    name: "PayAsYouGo",
    icon: "credits",
    component: () => {
      return import(
        /* webpackChunkName: "payAsYouGo" */ "../views/PayAsYouGo.vue"
      );
    },
    parentId: null,
    id: "payAsYouGo",
    showInSidebar: true
  },
  {
    path: "/faq",
    name: "FAQ",
    icon: "faq",
    component: null,
    parentId: null,
    id: "faq",
    showInSidebar: true
  },
  {
    path: "/faq/categories",
    name: "Categories",
    icon: "categories",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "categories" */ "../views/faq/Categories"
      );
    },
    parentId: "faq",
    id: "/faq/categories"
  },
  {
    path: "/faq/questions",
    name: "Questions",
    icon: "questions",
    showInSidebar: true,
    component: () => {
      return import(
        /* webpackChunkName: "questions" */ "../views/faq/Questions"
      );
    },
    parentId: "faq",
    id: "/faq/questions"
  },
  {
    path: "/about",
    name: "About product",
    icon: "about",
    showInSidebar: true,
    component: () => {
      return import(/* webpackChunkName: "about" */ "../views/About");
    },
    parentId: null,
    id: "about"
  }
];
