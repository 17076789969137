import { Commit } from "~~/vuex";
import { api } from "@/api/Api";
import {
  ITemplatesData,
  ITypeTemplateData
} from "@/store/interfaces/templates/ITemplateData";
import { ITemplatesDataResponse } from "@/api/interfaces/templates/ITemplatesResponseData";
import {
  templatesData,
  templatesQuery
} from "@/api/interfaces/templates/common";

export interface templatesState {
  templatesList: ITemplatesData[];
  totalTemplates: number;
  templatesTypesList: ITypeTemplateData[];
}

export const templates = {
  state(): templatesState {
    return {
      templatesList: [],
      totalTemplates: 0,
      templatesTypesList: []
    };
  },
  mutations: {
    setTemplates(state: templatesState, templates: ITemplatesData[]) {
      state.templatesList = templates;
    },
    setTemplatesTypes(state: templatesState, types: ITypeTemplateData[]) {
      state.templatesTypesList = types;
    },
    setTotalTemplates(state: templatesState, total: number) {
      state.totalTemplates = total;
    }
  },

  getters: {
    templatesList: ({ templatesList }: templatesState): ITemplatesData[] => {
      return templatesList;
    },
    templatesTypesList: ({
      templatesTypesList
    }: templatesState): ITypeTemplateData[] => {
      return templatesTypesList;
    },
    totalTemplates: ({ totalTemplates }: templatesState): number =>
      totalTemplates
  },

  actions: {
    async fetchTemplateData(
      {
        commit
      }: {
        commit: Commit;
      },
      {
        query,
        applicationId
      }: {
        query: templatesQuery;
        applicationId: number;
      }
    ): Promise<ITemplatesDataResponse> {
      // fetch and return new data templates
      const {
        success,
        data: response,
        message,
        status
      } = await api.fetchTemplates(query, applicationId);

      const dataItemplateData = <ITemplatesData>response;
      commit("setTemplates", dataItemplateData);
      commit("setTotalTemplates", dataItemplateData.length);
      return {
        success,
        status,
        message,
        data: dataItemplateData
      };
    },
    async fetchTemplatesTypes(
      { commit }: { commit: Commit },
      {
        applicationId
      }: {
        applicationId: number;
      }
    ) {
      const { data: response } = await api.fetchTemplatesTypes(applicationId);
      const dataItemplateData = <ITypeTemplateData>response;
      commit("setTemplatesTypes", dataItemplateData);
    },
    async addTemplate(
      {
        commit
      }: {
        commit: Commit;
      },
      {
        data
      }: {
        data: templatesData;
      }
    ): Promise<ITemplatesDataResponse> {
      const {
        success,
        data: response,
        message,
        status
      } = await api.addTemplate(data);

      const dataItemplateData = <ITemplatesData>response;
      return {
        success,
        status,
        message,
        data: dataItemplateData
      };
    },
    async editTemplateData(
      {
        commit
      }: {
        commit: Commit;
      },
      {
        data
      }: {
        data: templatesData;
      }
    ): Promise<ITemplatesDataResponse> {
      const {
        success,
        data: response,
        message,
        status
      } = await api.editTemplateData(data);

      const dataItemplateData = <ITemplatesData>response;
      return {
        success,
        status,
        message,
        data: dataItemplateData
      };
    },
    async deleteTemplate(
      {
        commit
      }: {
        commit: Commit;
      },
      {
        id
      }: {
        id: number;
      }
    ): Promise<ITemplatesDataResponse> {
      const {
        success,
        data: response,
        message,
        status
      } = await api.deleteTemplate(id);

      const dataItemplateData = <ITemplatesData>response;
      return {
        success,
        status,
        message,
        data: dataItemplateData
      };
    }
  }
};
