import { IBalances, IBalancesHistoryResponse,IUserBalanceResponse, balancesHistoryData, userBalanceData } from "@/api/interfaces/balances/common";

import { queryObjectToUrlString } from "@/utills/queryObjectToUrlString";

import { IApiResponse, IBaseQuery } from "@/api/interfaces/IApi";
import { apiRequestType } from "@/api/interfaces/IApi";
import { IBalanceOperation } from "@/hooks/balances/useBalances";
export const balancesService = {
  async fetchBalanceHistory(
    request: apiRequestType,
    userId:number,
    query: IBaseQuery
  ): Promise<balancesHistoryData> {
   const queryString: string = queryObjectToUrlString(query);
    const response = await request(
      `balances/balance-history/${userId}?${queryString}`,
      "get"
    ) as IBalancesHistoryResponse;

   const data = response.data;

    return {
      ...response,
      data,
    };
  },

  async fetchUserBalance(
    request: apiRequestType,
    userId: number
  ): Promise<userBalanceData> {
    const response = await request(`/balances/balance/${userId}`, "get") as IUserBalanceResponse;
    const data = response.data;

    return {
      ...response,
      data,
    };
  },

  async addBalanceOperation(
    request: apiRequestType,
    userId: number,
    balanceData: IBalanceOperation
  ): Promise<IApiResponse> {
    console.log({balanceData});
    const response = await request(`/balances/add-balance-operation/${userId}`, "post", balanceData) as IApiResponse;
    const data = response.data;

    return {
      ...response,
      data,
    };
  },
};
