<template>
  <label class="acc-label">
    <label v-if="label" :for="id">{{ label }}</label>
    <input
      :id="
        'input_' +
          Math.random()
            .toString(36)
            .substring(2, 9)
      "
      class="form-control"
      :type="type"
      v-bind="$attrs"
      @change="evt => $emit('change', evt)"
    />
  </label>
</template>

<script>
export default {
  name: "FormControl",
  props: {
    id: { type: String, required: true },
    type: {
      type: String,
      default: "text"
    },
    onChange: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => () => {}
    },
    name: {
      type: String,
      required: true
    },

    validators: Array,
    fieldType: { type: String, default: "default" },
    label: String
  },
  emits: {
    change: null
  },

  data() {
    return {
      value: ""
    };
  },

  watch: {
    value(changedValue) {
      this.onChange(changedValue);
    }
  }
};
</script>

<style scoped>
.acc-label {
  flex: 1;
  line-height: 1;
}

.form-control {
  font-weight: 500;
}
</style>
