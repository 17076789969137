import { PromoCodesDto } from "./../api/interfaces/promo-codes/promo-codes.dto";
import { Commit } from "~~/vuex";
import { api } from "@/api/Api";
import { accountsQuery } from "@/api/interfaces/accounts/filters";

import { IApiResponse } from "@/api/interfaces/IApi";
import { IPromoCode } from "./interfaces/promo-codes/promoCodes";
import { IApiPromoResponse } from "@/api/interfaces/promo-codes/common";
import { ref } from "vue";

export interface promoCodesState {
  promoCodesList: IPromoCode[];
  totalPromoCodes: number;
}
export const promoCodesModule = {
  state(): promoCodesState {
    return {
      promoCodesList: [],
      totalPromoCodes: 0
    };
  },

  getters: {
    promoCodesList: ({ promoCodesList }: promoCodesState): IPromoCode[] => {
      return promoCodesList;
    },
    totalPromoCodes: ({ totalPromoCodes }: promoCodesState): number =>
      totalPromoCodes
  },

  mutations: {
    setPromoCodes(state: promoCodesState, promo: IPromoCode[]) {
      state.promoCodesList = promo;
    },
    setTotalPayments(state: promoCodesState, total: number) {
      state.totalPromoCodes = total;
    }
  },

  actions: {
    async fetchPromoCodes(
      { commit }: { commit: Commit },
      { viewConfig }: { viewConfig: accountsQuery }
    ): Promise<IApiResponse> {
      const { data, success, message, status } = <IApiPromoResponse>(
        await api.getPromoCodes(viewConfig)
      );
      const { result, total } = data;
      if (result) {
        commit("setPromoCodes", result);
      }
      commit("setTotalPayments", total);
      return { success, message, status };
    },
    async actionWithPromoCode(
      _: any,
      { data, id }: { data: PromoCodesDto; id: number | null }
    ) {
      if (data?.id && data?.title) {
        const { success, message, status } = <IApiPromoResponse>(
          await api.updatePromoCode(data.id, data)
        );
        return { success, message, status };
      } else if (data?.title && !data?.id) {
        const { success, message, status } = <IApiPromoResponse>(
          await api.createPromoCode(data)
        );
        return { success, message, status };
      } else if (id) {
        const { success, message, status } = <IApiPromoResponse>(
          await api.deletePromoCode(id)
        );
        return { success, message, status };
      }
    }
  }
};
