import { Commit } from "vuex";
import { api } from "@/api/Api";
import { ICountriesDataPrice } from "./interfaces/data-price/IDataPrice";
import {
    IDataPriceResponse,
  dataPriceFilterSchema,
  dataPriceQuery
} from "@/api/interfaces/data-price/common";
import { IApiResponse } from "@/api/interfaces/IApi";
import { EditDataPriceModalDto } from "@/api/interfaces/data-price/common";

export interface dataPriceState {
  dataPriceList: ICountriesDataPrice[];
  totalDataPrice: number;
}
export const dataPriceModule = {
  state(): dataPriceState {
    return {
      dataPriceList: [],
      totalDataPrice: 0
    };
  },

  getters: {
    dataPriceList: ({
      dataPriceList
    }: dataPriceState): ICountriesDataPrice[] => {
      return [...dataPriceList];
    },
    totalDataPrice: ({ totalDataPrice }: dataPriceState): number => totalDataPrice
  },

  mutations: {
    setDataPrice(state: dataPriceState, dataPrice: ICountriesDataPrice[]) {
      state.dataPriceList = dataPrice;
    },
    setTotal(state: dataPriceState, totalDataPrice: number) {
      state.totalDataPrice = totalDataPrice;
    }
  },

  actions: {
    async fetchDataPriceList(
      { commit }: { commit: Commit },
      {
        query,
        filter
      }: { query: dataPriceQuery; filter: dataPriceFilterSchema }
    ): Promise<IDataPriceResponse> {
      const response: IDataPriceResponse = await api.fetchDataPrice(
        query,
        filter
      );

      const { data } = response;

      if (data && data.results) {
        commit("setDataPrice", data.results);
      }

      if (data && data.total) {
        commit("setTotal", data.total);
      }

      return response;
    },

    async editDataPrice(
      { commit }: { commit: Commit },
      { data: reqData }: { data: EditDataPriceModalDto }
    ): Promise<IApiResponse> {
      return await api.editDataPrice(reqData);
    },
  }
};
